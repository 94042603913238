// extracted by mini-css-extract-plugin
export var centerer = "b96fe";
export var container = "_2f092";
export var content = "_9ed32";
export var description = "c6a22";
export var fullHeight = "c10e4";
export var halfHeight = "a4c68";
export var image = "_95b05";
export var inner = "_8ea63";
export var title = "ac764";
export var topbarOffset = "e2d06";