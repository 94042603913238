import * as React from 'react';
import { kebabCase, isArray, isString, isUndefined, isFunction } from 'lodash';
import useCollapse from 'react-collapsed';
import clsx from 'clsx';
import Consumer from '@core/context/Context';
import CollapseIcon from '@components/CollapseIcon';
import { AnyObject } from '@interface/common';
import * as styles from './Collapsible.module.scss';


type BaseCollapsibleProps = {
  tagName?: string;
  title: string | (() => React.ReactNode);
  children: React.ReactNode | React.ReactNode[];
  triggerTagName?: string;
  className?: string;
  triggerClassName?: string;
  contentClassName?: string;
  activeId?: string | null;
  disabled?: boolean;
  onClick?: (id: string) => void;
}

const BaseCollapsible = ({
  title,
  children,
  activeId,
  className,
  triggerClassName,
  contentClassName,
  triggerTagName = 'button',
  tagName = 'div',
  disabled = false,
  onClick,
}: BaseCollapsibleProps) => {
  const componentId = React.useId();

  let id: string | undefined;
  if (isString(title)) {
    id = kebabCase(title);
  }
  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } = useCollapse({
    defaultExpanded: false,
  });

  React.useEffect(() => {
    if (!isUndefined(id) && isExpanded && activeId !== id) {
      setExpanded(false);
    }
  }, [activeId]);

  const isNotDOM = (child: BaseCollapsibleProps['children']) => {
    // @ts-ignore
    return !isUndefined(child?.type) && !isString(child?.type);
  };

  const renderContent = () => {
    if (isArray(children)) {
      return children.map((child, idx) => {
        let props: AnyObject = { key: `${componentId}-${idx}` };
        if (isNotDOM(child)) {
          props = { ...props, isExpanded, setExpanded };
        }
        // @ts-ignore
        return isString(child) ? child : React.cloneElement(child, props);
      });
    } else {
      let props: AnyObject = {};
      if (isNotDOM(children)) {
        props = { isExpanded, setExpanded };
      }
      // @ts-ignore
      return isString(children) ? children : React.cloneElement(children, props);
    }
  };

  const renderTrigger = (children: React.ReactNode) => {
    return React.createElement(triggerTagName, {
        ...getToggleProps({
          onClick: () => {
            if (!disabled) {
              setExpanded(p => p);
              id && onClick?.(id);
            }
          },
        }),
        className: clsx(styles.trigger, triggerClassName),
        ...(triggerTagName === 'button' ? {
          disabled,
        } : {
          type: undefined,
        }),
      },
      children,
    );
  };

  const render = () => (
    <>
      {renderTrigger(
        <>
          <span>{isFunction(title) ? title() : title}</span>
          <CollapseIcon isExpanded={isExpanded}/>
        </>,
      )}
      <div
        {...getCollapseProps()}
        className={styles.container}
      >
        <div className={clsx(styles.inner, contentClassName)}>
          {renderContent()}
        </div>
      </div>
    </>
  );

  return isUndefined(tagName) ? render() : React.createElement(tagName, { className }, render());
};

type Props = {
  tagName?: string;
  title: string | (() => React.ReactNode);
  children: React.ReactNode | React.ReactNode[] | string;
  disabled?: boolean;
  className?: string;
  triggerTagName?: string;
  triggerClassName?: string;
  contentClassName?: string;
}

const Single = ({ children, ...other }: Props) => (
  <BaseCollapsible {...other}>{children}</BaseCollapsible>
);

const Multiple = ({ children, ...other }: Props) => {
  return (
    <Consumer>
      {({ activeCollapsible, setActiveCollapsible }) => (
        <BaseCollapsible
          {...other}
          activeId={activeCollapsible}
          onClick={setActiveCollapsible}
        >
          {children}
        </BaseCollapsible>
      )}
    </Consumer>
  );
};

export { Single, Multiple as default };
