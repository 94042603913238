import * as React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { isString } from 'lodash';
import clsx from 'clsx';
import Centerer from '@components/Centerer';
import * as styles from './Hero.module.scss';


type Caption = (() => React.ReactNode) | string

type Props = {
  className?: string;
  children?: React.ReactNode;
  title: Caption;
  description: Caption;
  image: IGatsbyImageData | undefined;
  id?: string;
  fullHeight?: boolean;
  halfHeight?: boolean;
}

function Hero({
  className,
  children,
  title,
  description,
  image,
  id,
  fullHeight = false,
  halfHeight = false,
}: Props) {
  return (
    <section
      id={id}
      className={clsx(styles.container, className, {
        [styles.fullHeight]: fullHeight,
        [styles.halfHeight]: halfHeight,
        [styles.topbarOffset]: !fullHeight && !halfHeight,
      })}
    >
      {image && (
        <GatsbyImage
          className={styles.image}
          image={image}
          alt="Hero image"
        />
      )}
      <Centerer className={styles.centerer}>
        <div className={styles.inner}>
          {React.createElement(
            halfHeight ? 'h2' : 'h1',
            { className: styles.title },
            isString(title) ? title : title())
          }
          <p className={styles.description}>
            {isString(description) ? description : description()}
          </p>
        </div>
        {fullHeight && children}
      </Centerer>
      {halfHeight && children && (
        <Centerer className={styles.content}>
          {children}
        </Centerer>
      )}
    </section>
  );
}

export default React.memo(Hero, () => true);
