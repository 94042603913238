import * as React from 'react';
import clsx from 'clsx';
import PlusIcon from '@assets/plus.svg';
import * as styles from '@components/CollapseIcon.module.scss';


export type Props = React.SVGAttributes<any> & {
  isExpanded?: boolean;
};

export default function CollapseIcon({ className, isExpanded, ...other }: Props) {
  return (
    <PlusIcon {...other} className={clsx(styles.arrow, className, { [styles.arrowExpanded]: isExpanded })}/>
  );
}
