import GATSBY_COMPILED_MDX from "C:/Users/ribbe/WebstormProjects/rebel_thorp_umbrella/client/app/src/content/faq/index.mdx";
import * as React from 'react';
import {graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import {MDXProvider} from '@mdx-js/react';
import clsx from 'clsx';
import {BREAKPOINT, LABEL, POSITION} from '@core/constants';
import {getSiteMetaData, mailToURL} from '@core/utils';
import BaseHead from '@components/Head';
import Main from '@components/Main';
import Hero from '@components/Hero';
import Link from '@components/Link';
import Centerer from '@components/Centerer';
import Sidebar from '@components/Sidebar';
import Content from '@components/Content';
import Collapsible from '@components/Collapsible';
import * as styles from './faq.module.scss';
import {useRoutes} from '@core/hooks';
const SIDEBAR_BREAKPOINTS = [BREAKPOINT.NAME.DESKTOP, BREAKPOINT.NAME.WIDE, BREAKPOINT.NAME.ULTRA];
const shortcodes = {
  Link,
  Collapsible
};
function FaqTemplate({data, children}) {
  const {route, GUIDES} = useRoutes();
  const siteMetaData = getSiteMetaData(data);
  const heroImage = getImage(data.heroImage);
  return React.createElement(Main, null, React.createElement(Hero, {
    title: LABEL.FAQ,
    description: "Frequently Asked Questions",
    image: heroImage
  }), React.createElement(Centerer, {
    tagName: "section",
    withVerticalPadding: true,
    sidebar: POSITION.RIGHT,
    sidebarBreakpoints: SIDEBAR_BREAKPOINTS
  }, React.createElement(Sidebar, {
    sticky: true
  }, React.createElement("div", {
    className: "format"
  }, React.createElement("h3", null, "Getting started"), React.createElement("p", null, 'Need some help with your order or got a burning question that you need answered pronto? Just click ' + 'on one of the section to find the answer. If you still can\'t find an answer to your question, ' + 'please check ', React.createElement(Link, {
    to: route(GUIDES)
  }, LABEL.GUIDES), ' page or drop as an email.'), React.createElement("h3", null, "Speak human"), React.createElement("p", null, "General inquiries, partnership and media offers:"), React.createElement("p", {
    className: styles.email
  }, React.createElement(Link, {
    to: mailToURL({
      to: siteMetaData.emails.contact
    })
  }, siteMetaData.emails.contact)), React.createElement("p", null, "Customer product support:"), React.createElement("p", {
    className: styles.email
  }, React.createElement(Link, {
    to: mailToURL({
      to: siteMetaData.emails.support
    })
  }, siteMetaData.emails.support)))), React.createElement(Content, {
    className: clsx('format', styles.content)
  }, React.createElement(MDXProvider, {
    components: shortcodes
  }, children))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(FaqTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export function Head() {
  return React.createElement(BaseHead, {
    title: LABEL.FAQ
  });
}
export const query = graphql`
    query ($slug: String!) {
        site {
            siteMetadata {
                emails {
                    contact
                    support
                }
            }
        }
        mdx(fields: { source: { eq: "faq" }, slug: { eq: $slug }}) {
            id
        }
        heroImage: file(
            absolutePath: { glob: "**/src/images/faq.jpg" }
        ) {
            ...BackgroundImage
        }
    }
`;
