/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Collapsible, Link} = _components;
  if (!Collapsible) _missingMdxReference("Collapsible", true);
  if (!Link) _missingMdxReference("Link", true);
  return React.createElement(React.Fragment, null, React.createElement(Collapsible, {
    title: "Which game engines do you support?"
  }, React.createElement(_components.p, null, "We support only ", React.createElement(Link, {
    to: "https://www.unrealengine.com/",
    external: true
  }, "Unreal Engine"), " 4 and 5 and have no plans to\nwork with others.")), "\n", React.createElement(Collapsible, {
    title: "What's coming in the next release?"
  }, React.createElement(_components.p, null, "Take a look at our work in progress in the ", React.createElement(_components.a, {
    href: "/#forthcoming"
  }, "Forthcoming"), " section.")), "\n", React.createElement(Collapsible, {
    title: "Can I use your FPS products for TPS?"
  }, React.createElement(_components.p, null, "Weapon assets or weapon attachments assets can be used in both First-person (FP) and Third-person (TP) view. Meshes\nhave LODs and will be optimized for different screen percentage values (distance from camera). You can use already\nset values or tweak them on your own."), React.createElement(_components.p, null, "As for the animations, it depends."), React.createElement(_components.p, null, "Basically FP and TP animations have different look and feel. FP animations place more emphasis on dynamics, while TP\nanimations on realistic look. For example, replacing the weapon magazine when reloading - the moment when the hand is\noff-camera is quite accelerated to not waste the player's time and not let him get bored, while in TP - the hand has\nto reach the hip (pocket or belt) for a more realistic look. Most of actions in FP made in front of camera (face)\nwhich will look weird from TP view. Nobody aims down sights from a center of face in real life (TP) and etc."), React.createElement(_components.p, null, "However, FP animations may be used as a reference layer in the production of full body animations in 3D software."), React.createElement(_components.p, null, "In our experience, the best results for projects with the ability to switch the camera view are achieved by using two\ncharacter meshes at the same time. One - FP arms with it's AnimBlueprint, the other - TP full body with corresponding\nAnimBlueprint. Both sets of animations should have the same duration and structure. The main idea is to synchronize\nboth playing animations from the two AnimBlueprints. Meshes visibility for FP managed by \"Owner No See\" and \"Only\nOwner See\" params. This method also allows you to cast a shadow from the full body motion, while player see only FP\narms.")), "\n", React.createElement(Collapsible, {
    title: "Do you plan to make animations for TPS?"
  }, React.createElement(_components.p, null, "Not at the moment. This is a huge amount of work for which we don't yet have the resources.")), "\n", React.createElement(Collapsible, {
    title: "How to get rid of lower arm knotting after retargeting?"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Follow this guide to setup ", React.createElement(_components.a, {
    href: "/guides/animation#retargeting"
  }, "Retargeting"), " properly."), "\n", React.createElement(_components.li, null, "Check this notes about ", React.createElement(_components.a, {
    href: "/guides/animation#twist-correction"
  }, "Twist Correction"), " and ", React.createElement(_components.a, {
    href: "/guides/animation#custom-character-design"
  }, "Custom Character Design"), "."), "\n")), "\n", React.createElement(Collapsible, {
    title: "I want to buy several products at once, can you do a discount?"
  }, React.createElement(_components.p, null, "Check out our ", React.createElement(_components.a, {
    href: "/products/bundles"
  }, "Bundles"), " page.")), "\n", React.createElement(Collapsible, {
    title: "Can I ask for help with the purchased products?"
  }, React.createElement(_components.p, null, "Of course, if there is a question about the functionality of the packages or if you find a bug, write to us at\n", React.createElement(_components.a, {
    href: "mailto:support@rebelthorp.com"
  }, "support@rebelthorp.com"), "."), React.createElement(_components.p, null, "If you have issues with the technical part, please attach screenshots/video and specify the UE version (X.XX) your\nproject uses."), React.createElement(_components.p, null, "However, we are not an educational resource and can't work with you on your project step by step. Using our products\nrequires you to have at least intermediate programming skills and engine knowledge to communicate using the\nterminology and understand each other.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
